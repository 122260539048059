import { Spinner } from '@vapor/ui';

export let CenteredSpinner = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Spinner />
  </div>
);
