import { styled } from 'buttered';
import clsx from 'clsx';
import { GlobalNav } from '../nav/global';
import { SideBackground } from './sideBackground';

let Wrapper = styled('div')`
  min-height: calc(100vh - 61px);
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1300px) {
    grid-template-columns: auto 650px;
  }

  @media (max-width: 800px) {
    display: block;
  }

  &.noNav {
    min-height: 100vh;
  }
`;

let Aside = styled('aside')`
  display: flex;
  height: calc(100vh - 61px);
  position: sticky;
  top: 61px;

  &.noNav {
    height: 100vh;
    top: 0;
  }

  & > div {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

let Main = styled('main')`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 0px 150px 0px;

  & > div {
    max-width: 550px;
    width: 100%;
    padding: 0px 20px;
  }

  &.center {
    align-items: center;
  }
`;

export let SideLayout = ({
  children,
  center,
  noNav
}: {
  children: React.ReactNode;
  center?: boolean;
  noNav?: boolean;
}) => {
  return (
    <>
      {!noNav && <GlobalNav />}

      <Wrapper className={clsx({ noNav })}>
        <Aside className={clsx({ noNav })}>
          <div>
            <SideBackground />
          </div>
        </Aside>
        <Main className={clsx({ center })}>
          <div>{children}</div>
        </Main>
      </Wrapper>
    </>
  );
};
