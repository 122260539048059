export let SideBackground = () => (
  <svg
    viewBox="0 0 864 1117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g clipPath="url(#clip0_9_334)">
      <rect width="864" height="1117" fill="#B5DCE5" />
      <g filter="url(#filter0_f_9_334)">
        <ellipse cx="772.5" cy="570" rx="272.5" ry="250" fill="url(#paint0_linear_9_334)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_9_334"
        x="150"
        y="-30"
        width="1245"
        height="1200"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="175" result="effect1_foregroundBlur_9_334" />
      </filter>
      <linearGradient
        id="paint0_linear_9_334"
        x1="772.5"
        y1="320"
        x2="772.5"
        y2="820"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5C00" />
        <stop offset="1" stopColor="#F3BE02" />
      </linearGradient>
      <clipPath id="clip0_9_334">
        <rect width="864" height="1117" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
