import { styled } from 'buttered';

export let SideLayoutHeader = styled('header')`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  a {
    color: var(--vapor-foreground);
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
    transition: all 0.2s ease-in-out;
    width: fit-content;

    span {
      transition: all 0.2s ease-in-out;
      display: inline-block;
    }

    &:hover,
    &:focus {
      span {
        transform: translateX(-5px);
      }
    }
  }

  h1 {
    font-weight: 600;
    font-size: 30px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    margin-top: 10px;
  }
`;
