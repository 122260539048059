import { Button, Entity } from '@vapor/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { CenteredSpinner } from '../../components/atoms/centeredSpinner';
import { SideLayout } from '../../components/layout/sideLayout';
import { SideLayoutHeader } from '../../components/layout/sideLayout/header';
import { useAuth, useMustBeAuthenticated } from '../../state/auth';

export default () => {
  let router = useRouter();
  let { user, teams } = useAuth();
  useMustBeAuthenticated();

  useEffect(() => {
    if (teams && teams.length == 0) {
      router.push('/teams/create');
    }
  }, [teams]);

  return (
    <SideLayout>
      {user ? (
        <>
          <SideLayoutHeader>
            <h1>Choose your team</h1>
            <p>Hey, {user?.firstName}! These are the SparkAuth teams you are a member of.</p>
          </SideLayoutHeader>

          {teams?.map(team => (
            <Link
              href={`/team/${team.slug}`}
              key={team.id}
              style={{ display: 'block', color: 'unset' }}
            >
              <Entity.Wrapper key={team.id} style={{ marginBottom: 15 }}>
                <Entity.Content>
                  <Entity.Field
                    title={team.name}
                    description={team.slug}
                    prefix={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ width: 34, height: 34, borderRadius: '50%' }}
                          alt={team.name}
                          src={team.imageUrl}
                        />
                      </div>
                    }
                  />
                </Entity.Content>
              </Entity.Wrapper>
            </Link>
          ))}

          <Link href="/teams/create" style={{ display: 'block', color: 'unset' }}>
            <Button variant="secondary">Create a new team</Button>
          </Link>
        </>
      ) : (
        <CenteredSpinner />
      )}
    </SideLayout>
  );
};
